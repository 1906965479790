import { bodyModel } from "./bodyModel";

export default {
    data: () => ({
        body: {},
        isEdit: false
    }),
    created() {
        var systemEdit = sessionStorage.getItem("system");
        if(systemEdit){
            this.isEdit = true
           this.body = JSON.parse(systemEdit);
        }else{
            this.body = JSON.parse(JSON.stringify(bodyModel));
        }
        this.validateForm = {
            required: [v => !!v || 'Campo obrigatório'],
        }
    },
    beforeDestroy(){
        sessionStorage.removeItem('system');
    },
    methods: {
        addField() {
            this.body.systemFields.push(JSON.parse(JSON.stringify(bodyModel.systemFields[0])))
        },
        removeField(id) {
            this.body.systemFields.splice(id, 1)
        },
        validForm() {
            if (!this.$refs.form.validate()) {
                this.$toasted.global.error({ msg: 'Preencha todos os campos' })
                return;
            }
            this.SaveSystem();
        },
        async SaveSystem(){
            var systemEdit = sessionStorage.getItem("system");
            if (systemEdit) {
                await this.updateSystem();
                return;
            }
            this.addSystem();
        },
        async addSystem() {
            const resp = await this.DONUZ.System.PostSystem(this.body);
            if (resp.id) {
                this.$toasted.global.success();
                this.$router.push('/admin')
            }
        },
        async updateSystem(){
            const response = await this.DONUZ.System.PutSystem(this.body.id, this.body);
            if (response.statusCode == 200) {
                this.$toasted.global.success();
                this.$router.push('/admin')
                sessionStorage.removeItem('system');
                return;
            }
        }
    }
}