export const bodyModel = {
    systemName: "",
    logo: "",
    description: "",
    systemFields: [
        {
            key: "",
            value: "",
            type: "",
            placeHolder: "",
            tooltip: ""
        }
    ]
}